/** @jsx jsx */
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { PreVestibularTabs } from "@/data"
import { jsx } from '@emotion/react'
import { Link } from "gatsby"
import ArrowUp from '../assets/images/lp/icons/arrow-up.svg'

import {
  chooseUsTitle,
  contentBox,
  btnBox
} from "../assets/styles/FeaturedTab1.styles";

const FeatureTabOne = () => {
  const [active, setActive] = useState(0);
  const { posts } = PreVestibularTabs;
  return (
    <section>
      <Container>
        <div id="tabs">
          <Row>
            <Col lg={12}>
              <ul css={chooseUsTitle}>
                {posts.map(({ title }, index) => (
                  <li
                    key={index}
                    className={`${active === index ? "active" : " "}`}
                  >
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setActive(index);
                      }}
                    >
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <div className="tab-content">
            {posts.map((post, index) => {
              const {btnAvisita, btnGcurricular} = post
              return index === active ? (
                <div
                  className="tab-pane fade show active animated fadeIn"
                  id={`tes_tab_${index}`}
                  key={index}
                >
                  <Row
                    className={`${
                      0 === index % 2 ? " " : "flex-lg-row-reverse"
                    }`}
                  >
                    <Col lg={12}>
                      <div css={contentBox}>
                        <p>{post.contentL1}</p>
                        <p>{post.contentL2}</p>
                        <p>{post.contentL3}</p>
                        <p>{post.contentL4}</p>
                        <p>{post.contentL5}</p>
                        <div css={btnBox}>
                          <Link to={btnAvisita.url}>
                            {btnAvisita.label}
                          </Link>
                          {/* <Link to={btnGcurricular.url}>
                            {btnGcurricular.label}
                            <img src={ArrowUp} />
                          </Link> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null;
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FeatureTabOne;
