/** @jsx jsx */
import { css } from "@emotion/react";

export const FundamentalContent = css`
  background: #ffffff;
  padding: 80px 0;
  overflow: hidden;
  position: relative;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 64px 0;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 64px 0;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 64px 0;
  }
`

export const EnsinoMedioContent = css`
  background: #F9F9F9;
  padding: 80px 0;
  overflow: hidden;
  position: relative;
  p {
    color: #9D9C9B;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 64px 0;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 64px 0;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 64px 0;
  }
`

export const PreVestibularContent = css`
  background: #ffffff;
  padding: 80px 0;
  overflow: hidden;
  position: relative;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 64px 0;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 64px 0;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 64px 0;
  }
`

export const imgSect = css`
  width: 100%;
`

export const imgBox = css`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  /* margin: auto 0; */
  width: 50%;
  z-index: 10;
  div h2 {
    color: #fff;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

export const imgBoxL = css`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  /* margin: auto 0; */
  width: 50%;
  z-index: 10;
  div h2 {
    color: #fff;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

export const boxContent = css`
  /* padding-left: 90px; */
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #9D9C9B;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0px;
    width: 100%;
    max-width: 475px;
  }
  h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    font-family: "normalidad-variable", sans-serif;
    font-variation-settings: "wght" 130, "wght" 600;
    margin-bottom: 40px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
    h2 {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
    h2 {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
    h2 {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

export const boxContentL = css`
  padding-left: 90px;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #9D9C9B;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0px;
    width: 100%;
    max-width: 475px;
  }
  h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    font-family: "normalidad-variable", sans-serif;
    font-variation-settings: "wght" 130, "wght" 600;
    margin-bottom: 40px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding-left: 0px;
    img {
      margin-bottom: 40px;
      width: 100%;
    }
    h2 {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding-left: 0px;
    img {
      margin-bottom: 40px;
      width: 100%;
    }
    h2 {
      text-align: center;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding-left: 0px;
    img {
      margin-bottom: 40px;
      width: 100%;
    }
    h2 {
      text-align: center;
    }
  }
`

export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: inline;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: inline;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`
