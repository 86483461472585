/** @jsx jsx */
import { css } from "@emotion/react";

export const chooseUsTitle = css`
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  :first-child li {
    margin-right: 8px;
  }
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 47%;
    display: inline-block;
    position: relative;
    float: left;
    &:hover, &.active {
      a {
        background: #4BE66A;
        border-radius: 32px;
        justify-content: center;
        display: flex;
        align-items: center;
        text-decoration: none;
      }
    }

    a {
      justify-content: center;
      display: flex;
      align-items: center;
      background: #F9F9F9;
      border-radius: 32px;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #312E2D;
      width: 100%;
      text-align: center;
      height: 40px;
    }
  }
`

export const contentBox = css`
  position: relative;
  margin-top: 32px;
  font-family: 'Montserrat', sans-serif;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #9D9C9B;
    :not(:last-child) {
      margin-bottom: 8px;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
   p {
     text-align: center;
   }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    p {
      text-align: center;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    p {
      text-align: center;
    }
  }
`

export const btnBox = css`
  display: flex;
  align-items: center;
  font-family: "normalidad-variable", sans-serif;
  margin-top: 40px;
  a:first-child {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
    font-variation-settings: "wght" 700;
    background: #00854E;
    width: 212px;
    height: 52px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 32px;
    :hover {
      color: #ffffff;
      background: #312E2D;
      text-decoration: none;
    }
    :active {
      background: #F7FF53;
      color: #312E2D;
    }
  }
  a {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #312E2D;
    font-variation-settings: "wght" 700;
    img {
      margin-left: 8px;
    }
    :hover {
      color: #312E2D;
      text-decoration: none;
    }
    :active {
      color: #F7FF53;
      text-decoration: none;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    a {
      display: flex;
      align-items: center;
      img {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        width: initial;
      }
    }
    a:first-child {
      margin-right: 0px;
      margin-bottom: 32px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    a:first-child {
      margin-right: 0px;
    }
    
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
    flex-direction: column;
    a:first-child {
      margin-right: 0px;
    }
  }
`
