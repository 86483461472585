/** @jsx jsx */
import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import "react-modal-video/css/modal-video.min.css"
import { jsx } from '@emotion/react'
import TabsFundamental from '@/components/FundamentalTabs'
import TabsEnsinoMedio from '@/components/EnsinoMedioTabs'
import TabsPreVestibular from '@/components/PreVestibularTabs'
import ImgEnsinoFundamental from '../assets/images/lp/turmas/ensino-fundamental.png'
import ImgEnsinoMedio from '../assets/images/lp/turmas/ensino-medio.png'
import ImgPreVestibular from '../assets/images/lp/turmas/pre-vestibular.png'

import { 
  FundamentalContent,
  EnsinoMedioContent,
  PreVestibularContent,
  imgBox,
  imgBoxL,
  boxContent,
  boxContentL,
  DDesktop,
  DMobile,
  imgSect
}  from '../assets/styles/TurmasPageContent.styles'

const TurmaContentPage = () => {
  return (
    <Fragment>
      <div css={DMobile}>
        <img css={imgSect} src={ImgEnsinoFundamental} alt='/' />
      </div>
      <section css={FundamentalContent} id='ensino-fundamental'>
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div css={boxContent}>
                <h2>Ensino Fundamental</h2>
                <div>
                  <TabsFundamental />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div css={[imgBox, DDesktop]}>
          <img src={ImgEnsinoFundamental} alt='/' />
        </div>
      </section>
      <div css={DMobile}>
        <img css={imgSect} src={ImgEnsinoMedio} alt='/' />
      </div>
      <section css={EnsinoMedioContent} id='ensino-medio'>
        <div css={[imgBoxL, DDesktop]}>
          <img src={ImgEnsinoMedio} alt='/' />
        </div>
        <Container>
          <Row>
            <Col lg={6} sm={12} />
            <Col lg={6} sm={12}>
              <div css={boxContentL}>
                <h2>Ensino Médio</h2>
                <div>
                  <TabsEnsinoMedio />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div css={DMobile}>
        <img css={imgSect} src={ImgPreVestibular} alt='/' />
      </div>
      <section css={PreVestibularContent} id='pre-vestibular'>
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div css={boxContent}>
                <h2>Pré-vestibular</h2>
                <div>
                  <TabsPreVestibular />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div css={[imgBox, DDesktop]}>
          <img src={ImgPreVestibular} alt='/' />
        </div>
      </section>
    </Fragment>
  );
};

export default TurmaContentPage;
